export function baseUrl() {
    const env = process.env.NODE_ENV || 'development'
    // let baseURL =  'https://api-app.maisonier.com';
    let baseURL = 'http://localhost:4002';
    if (env === 'production') {
        baseURL = process.env.API_URL || 'https://maisonier-gestion-api-c53efb573b16.herokuapp.com' //'https://api-app.maisonier.com' // 'https://fse-le-maisonier.herokuapp.com/api';
        // baseURL = process.env.API_URL || 'http://localhost:4002' // 'https://fse-le-maisonier.herokuapp.com/api';
    }
    else if (env === 'test') { 

    }
    
    return baseURL;
}

export function apiUrl(url) {
    return `${baseUrl()}/api${url || ''}`;
}